import React from "react";
import Container from 'react-bootstrap/Container';
import { Helmet } from "react-helmet"

export default () => 
<div>
  <Helmet>
    <meta charSet="utf-8" />
    <title>BrainEngine</title>

    <meta name="title" content="BrainEngine" />
    <meta name="description" content="emPOWERING the minds of tomorrow" />
    
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.brainengine.us/" />
    <meta property="og:title" content="BrainEngine" />
    <meta property="og:description" content="emPOWERING the minds of tomorrow" />
    <meta property="og:image" content="https://www.brainengine.us/og.png" />
    
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://www.brainengine.us/" />
    <meta property="twitter:title" content="BrainEngine" />
    <meta property="twitter:description" content="emPOWERING the minds of tomorrow" />
    <meta property="twitter:image" content="https://www.brainengine.us/og.png" />
  </Helmet>
  <Container>
    <div className="text-white text-area">
      <div>
      <h1 className="display-3">BrainEngine</h1>
      <p className="lead"><em>emPOWERING the minds of tomorrow</em></p>
      <form name="contact" netlify-honeypot="bot-field" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-group d-none">
        <label>Don’t fill this out if you're human:<input name="bot-field" /></label>
        </div>
        <div className="form-group">
            <label>
              <span className="sr-only">Enter your email to stay up to date with the latest from BrainEngine</span>
              <input type="email" className="form-control" name="Email" placeholder="Your email" />
            </label>
        </div>
        <button type="submit" className="btn btn-danger">Subscribe</button>
      </form>
      </div>
    </div>
    <div className="copyright">
      <p>&copy; BrainEngine</p>
    </div>
  </Container>
</div>